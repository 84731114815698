import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { containerMaxWidth } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import { useLocation } from "@reach/router"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 14.2rem auto;
  padding: 0 3rem;
`

const Title = styled.h1`
  padding: 3rem;
  text-align: center;
`

const Text = styled.p`
  padding: 0;
  text-align: center;
`

const ErrorPage = ({ pageContext }) => {
  const location = useLocation()
  const { error } = location.state || {}
  const { t } = useTranslation("translations")
  const { loginEnabled } = pageContext.sanityLoginPage
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  return (
    <LayoutComponent
      meta={{ title: "Something went wrong!" }}
      invertedHeader
      pageContext={pageContext}
    >
      <Container>
        {error === "Reservation quantity limit exceeded" ? (
          <>
            <Title>{t("Cart limit reached!")}</Title>
            <Text>
              {t(
                "You've reached the cart limit, you cannot add any more items to your cart."
              )}
            </Text>
          </>
        ) : (
          <>
            <Title>{t("Something went wrong!")}</Title>
            <Text>
              {t("We are currently experiencing some technical difficulties.")}
              <br />
              {t("Please try again in a few moments.")}
            </Text>
          </>
        )}
      </Container>
    </LayoutComponent>
  )
}

export default ErrorPage
